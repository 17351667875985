<!-- 语法 -->
<template>
    <div class="">

    </div>
</template>

<script>
    //import '../js/font.js' //js文件引入，注意路径
    //import {myfun} from '../js/test.js' //js文件引入，注意路径
    //import Introduction from '@/views/Introduction.vue'
    //import api from '@/js/method'
    export default {
        name: '',
        components: {

        },
        data() { // 数据源
            return {
            }
        },
        mounted() {
            alert('暂未开放!')
        },
        computed: {
         
        },
        methods: { // 函数体部分，js的主要逻辑控制               
        },
        props: {
        }
    }
</script>

<style scoped>
    /* @import './swiper.css'; */
</style>